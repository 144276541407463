import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { ContactApi } from "../../api/ContactApi";



export const getRecument= createAsyncThunk(
  "contact/getRecument",
  async ()=>{
      const listRecuments = await ContactApi.getRecument();
      return listRecuments
  }
  
)
export const getDetailRecument= createAsyncThunk(
    "contact/getDetailRecument",
    async (id)=>{
        const getDetailRecument= await ContactApi.getDetailRecument(id);
        return getDetailRecument
    }
    
)


const contactSlice = createSlice({
    name :"contact",
    initialState: {
        listRecument :[],
detailRecument:{},
        loadingSupport:false,
    },
    reducers:{

    },
    extraReducers:{
        [getRecument.pending]: (state) => {
            state.loadingSupport = true;
          },
          [getRecument.rejected]: (state) => {
            state.loadingSupport = false;
          },
          [getRecument.fulfilled]: (state, action) => {
            state.loadingSupport = false;
            state.listRecument = action.payload.data;
          },
          [getDetailRecument.pending]: (state) => {
            state.loadingSupport = true;
          },
          [getDetailRecument.rejected]: (state) => {
            state.loadingSupport = false;
          },
          [getDetailRecument.fulfilled]: (state, action) => {
            state.loadingSupport = false;
            state.detailRecument = action.payload.data;
          },

    }

})

const { reducer: contactReducer } = contactSlice;
export default contactReducer;