import React from 'react';
import paths from '../constants/path';
// import ProductItem from '../pages/aboutUs/NewProduct/ProductItem';

//User Page
const Home = React.lazy(() => import('../pages/Home'));
const HomeAdmin = React.lazy(() => import('../admin/HomeAdmin'));
const Login = React.lazy(() => import('../pages/HomeLogin'));
const Register = React.lazy(() => import('../pages/account/Register'));
const ForgetPassword = React.lazy(() => import('../pages/account/ForgetPassword'));
const ChangePassword = React.lazy(() => import('../pages/account/ChangePassword'));
const DetailNews = React.lazy(() => import('../pages/DetailNews.js'));

//PRODUCT
// const Product = React.lazy(() => import('../pages/product'));
const Product = React.lazy(() => import('../pages/ProductV1'));
// const ListProduct = React.lazy(() => import('../pages/ProductV1/product-list'));
// const ListProduct = React.lazy(() => import('../pages/ProductV1/detail'));
const ListProduct = React.lazy(() => import('../pages/ProductV1/LayoutProduct'));
const DetailProduct = React.lazy(() => import('../pages/ProductV1/detailProduct.js'));
const ListVehicleField = React.lazy(() => import('../pages/product/VehicleField/ListVehicleField'));
const TireSensor = React.lazy(() => import('../pages/product/VehicleField/CardTireSensor'));

//AboutUs
const PageAbout = React.lazy(() => import('../pages/aboutUsV1'));

const CeoMessage = React.lazy(() => import('../pages/aboutUs/CEO/CeoMessage'));
const Company = React.lazy(() => import('../pages/aboutUs/Companny'));
const Buisseness = React.lazy(() => import('../pages/aboutUs/Bussiness/Bussiness'));
const OurMission = React.lazy(() => import('../pages/aboutUs/OurMission/'));
const Quality = React.lazy(() => import('../pages/aboutUs/Quality'));
const Activity = React.lazy(() => import('../pages/aboutUs/Activity'));
const ActivityDetail = React.lazy(() => import('../pages/aboutUs/Activity/ActiviitiesDetail'));
const History = React.lazy(() => import('../pages/aboutUs/History'));
const Location = React.lazy(() => import('../pages/aboutUs/Location'));
const ContactUs = React.lazy(() => import('../pages/aboutUs/Contact/'));
const Partner = React.lazy(() => import('../pages/aboutUs/Partner'));
const Promotion = React.lazy(() => import('../pages/aboutUs/Promotion'));
const New = React.lazy(() => import('../pages/aboutUs/New-About'));
const NewProduct = React.lazy(() => import('../pages/aboutUs/NewProduct'));
const NewProductDetail = React.lazy(() => import('../pages/aboutUs/NewProduct/ProductItem'));
const Announced = React.lazy(() => import('../pages/aboutUs/Announced'));
const Documemnt = React.lazy(() => import('../pages/aboutUs/Announced'));
const Report = React.lazy(() => import('../pages/aboutUs/Announced'));
const SaleChanel = React.lazy(() => import('../pages/aboutUs/SalesChanel'));

//Contact
// const Contact = React.lazy(() => import('../pages/contact'));
const Recruiment = React.lazy(() => import('../pages/contactV1/Recruiment'));
const Detail = React.lazy(() => import('../pages/contactV1/Recruiment/Detail'));
const Contact = React.lazy(() => import('../pages/contactV1'));
const Cooperate = React.lazy(() => import('../pages/contact/cooperate'));
const Ecore = React.lazy(() => import('../pages/contact/ecoremerce'));
const RecuitmentDetail = React.lazy(() => import('../pages/contact/recruitment/DetailRecuitment'));

//Support
const Support = React.lazy(() => import('../pages/supportV1'));
const DetailSupport = React.lazy(() => import('../pages/supportV1/detailSupport'));

//Application
const Application = React.lazy(() => import('../pages/applicationV1'));
const ListApplication = React.lazy(() => import('../pages/applicationV1/LayoutApplication'));
// const LegalApplication = React.lazy(() => import('../pages/applicationV1/legal'));
// const EduApplication = React.lazy(() => import('../pages/applicationV1/edu'));
// const AgriApplication = React.lazy(() => import('../pages/applicationV1/agri'));
// const HealthApplication = React.lazy(() => import('../pages/applicationV1/health'));
// const ErpApplication = React.lazy(() => import('../pages/applicationV1/erp'));
// const TelecommunicationApplication = React.lazy(() => import('../pages/applicationV1/telecommunication'));
const DetailApp = React.lazy(() => import('../pages/applicationV1/Detail'));
const DetailApplication = React.lazy(() => import('../pages/applicationV1/detailApplication'));

// const Application = React.lazy(() => import('../pages/application'));
// const ApplicationEcore = React.lazy(() => import('../pages/application/EcomerceFileld'));
const ApplicationLegal = React.lazy(() => import('../pages/application/LegalTechnologyField'));

//service
const Service = React.lazy(() => import('../pages/serviceV1'));
const DetailService = React.lazy(() => import('../pages/serviceV1/detailService'));

const TermOfUse = React.lazy(() => import('../pages/termOfUse/TermOfUse'));
const PrivacyPolicy = React.lazy(() => import('../pages/termOfUse/PrivacyPolicy'));
const LegalPolicy = React.lazy(() => import('../pages/termOfUse/LegalPolicy'));
const SiteMap = React.lazy(() => import('../pages/termOfUse/SiteMap'));

const publicRoute = [
    { path: paths.HOME, component: Home },
    { path: paths.LOGIN, component: Login },
    { path: paths.REGISTER, component: Register },
    { path: paths.FORGET, component: ForgetPassword },
    { path: paths.CHANGE_PASSWORD, component: ChangePassword },
    { path: paths.DETAIL_NEWS, component: DetailNews },

    //PRODUCT
    { path: paths.PRODUCT, component: Product },
    { path: paths.LISTPRODUCT, component: ListProduct },
    { path: paths.AUTOMATIVEPRODUCT, component: DetailProduct },
    { path: paths.LIST_VEHICLE_FILE, component: ListVehicleField },
    { path: paths.PRODUCT, component: Product },
    { path: paths.TIRE_SENSOR, component: TireSensor },

    //AboutUs
    // { path: paths.ABOUT, component: CeoMessage },
    { path: paths.ABOUT, component: PageAbout },
    { path: paths.CEO, component: CeoMessage },
    { path: paths.COMPANY_INFO, component: Company },
    { path: paths.BUSSINESS, component: Buisseness },
    { path: paths.OURMISSION, component: OurMission },
    { path: paths.QUALITY, component: Quality },
    { path: paths.ACTIVITY, component: Activity },
    { path: paths.ACTIVITYDETAIL, component: ActivityDetail },
    { path: paths.HISTORY, component: History },
    { path: paths.LOCATION, component: Location },
    { path: paths.CONTACTUS, component: ContactUs },
    { path: paths.PARTNER, component: Partner },
    { path: paths.PROMOTION, component: Promotion },
    { path: paths.NEWS, component: New },
    { path: paths.NEWPRODUCT, component: NewProduct },
    { path: paths.NEWPRODUCTDETAIL, component: NewProductDetail },
    { path: paths.CORPORATION, component: Partner },
    { path: paths.ANNOUNCED, component: Announced },
    { path: paths.DOCUMENT, component: Documemnt },
    { path: paths.REPORT, component: Report },
    //
    { path: paths.DOMESTIC, component: SaleChanel },
    { path: paths.INTERNATIONAL, component: SaleChanel },
    { path: paths.ONLINE, component: SaleChanel },

    //Contact
    { path: paths.RECRUIMENT, component: Recruiment },
    { path: paths.DETAIL, component: Detail },
    { path: paths.CONTACT, component: Contact },
    { path: paths.COOPERATE, component: Cooperate },
    { path: paths.ECOREMERCE, component: Ecore },
    { path: paths.RECUITMENT_DETAIL, component: RecuitmentDetail },

    //SUPPORT
    { path: paths.SUPPORT, component: Support },
    { path: paths.SUPPORT_DETAIL, component: DetailSupport },

    //APPLICATOIN

    { path: paths.APPLICATION, component: Application },
    { path: paths.LISTAPPLICATION, component: ListApplication },
    // { path: paths.LEGALAPPLICATION, component: LegalApplication },
    // { path: paths.EDUAPPLICATION, component: EduApplication },
    // { path: paths.AGRIAPPLICATION, component: AgriApplication },
    // { path: paths.HEALTHAPPLICATION, component: HealthApplication },
    // { path: paths.ERPAPPLICATION, component: ErpApplication },
    // { path: paths.TELECOMMUNICATIONAPPLICATION, component: TelecommunicationApplication },
    // { path: paths.APPLICATION_ECOREMERCE, component: ApplicationEcore },
    { path: paths.DETAILAPPLICATIONFEATURE, component: DetailApp },
    { path: paths.APPLICATION_LEGAL_TECHNOLOGY, component: ApplicationLegal },
    { path: paths.DETAIL_LEGAL_TECHNOLOGY, component: DetailApplication },

    //service

    { path: paths.SERVICE, component: Service },
    { path: paths.SERVICE_DETAIL, component: DetailService },

    { path: paths.TERM_OF_USE, component: TermOfUse },
    { path: paths.PRIVACY_POLICY, component: PrivacyPolicy },
    { path: paths.LEGAL_POLICY, component: LegalPolicy },
    { path: paths.SITE_MAP, component: SiteMap },
];
const privateRoutes = [{ path: paths.ADMINHOME, component: HomeAdmin }];

export { privateRoutes, publicRoute };
