import axios from 'axios';
import { useEffect } from 'react';

const langType = localStorage.getItem('language');
console.log('langType', langType);
const lang = () => {
    return localStorage.getItem('language');
};
const axiosClient = axios.create({
    baseURL: 'https://api.lhcgroup.vn/api/v1/',
    headers: {
        'content-type': 'application/json',
        lang: langType,
    },
});

axiosClient.interceptors.response.use(
    (response) => {
        if (response && response.data) {
            return response.data;
        }
        return response;
    },
    // async (errors) => {
    //     if (errors.response?.status === 401) {
    //         const originalRequest = errors.config;
    //         userApi
    //             .getToken({
    //                 refresh_Token: refreshToken,
    //             })
    //             .then((res) => {
    //                 originalRequest.headers.Authorization = `Bearer ${res.token}`;
    //                 localStorage.setItem("access_Token", res.token);
    //                 return axiosClient.request(originalRequest);
    //             });
    //     }
    //     return Promise.reject(errors);
    // }
);

export default axiosClient;
