import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { applicationApi } from '../../api/ApplicationApi';

export const getCateApp = createAsyncThunk('application/getCateApp', async () => {
    const listField = await applicationApi.getCateApplication();
    return listField;
});

export const getapplicationById = createAsyncThunk('application/getprobyId', async (cateId) => {
    const listapplicationbyId = await applicationApi.getAppById(cateId);
    return listapplicationbyId;
});
export const getDetailapplication = createAsyncThunk('application/detail', async (id) => {
    const detailapplication = await applicationApi.getDetail(id);
    return detailapplication;
});
export const getListApplication = createAsyncThunk('application/listApplication', async () => {
    const res = await applicationApi.getListApplication();
    return res;
});
export const getListCategoryApplications = createAsyncThunk('application/listCategoryApplication', async (id) => {
    const res = await applicationApi.getListCategoryApplication(id);
    return res;
});

const applicationSlice = createSlice({
    name: 'application',
    initialState: {
        listApplicationbyId: [],
        loadingApplication: false,
        listApplication: [],
        listCategoryApplication: [],
        detail: {},
        listCate: [],
    },
    reducers: {},
    extraReducers: {
        [getapplicationById.pending]: (state) => {
            state.loadingApplication = true;
        },
        [getapplicationById.rejected]: (state) => {
            state.loadingApplication = false;
        },
        [getapplicationById.fulfilled]: (state, action) => {
            state.loadingApplication = false;
            state.listApplicationbyId = action.payload.data;
        },
        [getDetailapplication.pending]: (state) => {
            state.loadingApplication = true;
        },
        [getDetailapplication.rejected]: (state) => {
            state.loadingApplication = false;
        },
        [getDetailapplication.fulfilled]: (state, action) => {
            state.loadingApplication = false;
            state.detail = action.payload.data;
        },
        [getCateApp.pending]: (state) => {
            state.loadingApplication = true;
        },
        [getCateApp.rejected]: (state) => {
            state.loadingApplication = false;
        },
        [getCateApp.fulfilled]: (state, action) => {
            state.loadingApplication = false;
            state.listCate = action.payload.data;
        },
        [getListApplication.pending]: (state) => {
            state.loadingApplication = true;
        },
        [getListApplication.rejected]: (state) => {
            state.loadingApplication = false;
        },
        [getListApplication.fulfilled]: (state, action) => {
            state.loadingApplication = false;
            state.listApplication = action.payload.data;
        },
        [getListCategoryApplications.pending]: (state) => {
            state.loadingApplication = true;
        },
        [getListCategoryApplications.rejected]: (state) => {
            state.loadingApplication = false;
        },
        [getListCategoryApplications.fulfilled]: (state, action) => {
            state.loadingApplication = false;
            state.listCategoryApplication = action.payload.data;
        },
    },
});

const { reducer: applicationReducer } = applicationSlice;
export default applicationReducer;
