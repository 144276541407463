import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { SupportApi } from "../../api/SupportApi";


export const getSupports= createAsyncThunk(
  "support/getSupport",
  async (type)=>{
      const listSupport = await SupportApi.getSupport(type);
      return listSupport
  }
  
)


const supportSlice = createSlice({
    name :"support",
    initialState: {
        getlistSupports :[],

        loadingSupport:false,
    },
    reducers:{

    },
    extraReducers:{
        [getSupports.pending]: (state) => {
            state.loadingSupport = true;
          },
          [getSupports.rejected]: (state) => {
            state.loadingSupport = false;
          },
          [getSupports.fulfilled]: (state, action) => {
            state.loadingSupport = false;
            state.getlistSupports = action.payload.data;
          },
    }

})

const { reducer: supportReducer } = supportSlice;
export default supportReducer;