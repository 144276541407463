import axiosClient from './AxiosClient';

const HomeApi = {
    getBanner() {
        const url = 'home/banners';
        return axiosClient.get(url);
    },
    searchByKeyword(keyword) {
        const url = `products/search?keyword=${keyword}`;
        return axiosClient.get(url);
    },
    getNews() {
        const url = 'news';
        return axiosClient.get(url);
    },
    registerNews(data) {
        const url = 'user-accept-receive-news';
        return axiosClient.post(url, data);
    },
    getPartner() {
        const url = 'partner/all-paging?page=1&size=30';
        return axiosClient.get(url);
    },
    getDetailNews(id) {
        const url = `news/${id}`;
        return axiosClient.get(url);
    },
};
export default HomeApi;
