import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './translate/i18n';

//admin
// import './admin/assets/boxicons-2.0.7/css/boxicons.min.css';
// import './admin/assets/boxicons-2.0.7/css/boxicons.min.css';
// import './admin/assets/css/grid.css';
// import './admin/assets/css/theme.css';
// import './admin/assets/css/index.css';

import { Provider } from 'react-redux';
import { ContextProvider } from './contexts/ContextProvider';
import store from './redux/store/index.';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            {' '}
            <ContextProvider>
                <App />
            </ContextProvider>
        </Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
