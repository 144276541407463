import axiosClient from './AxiosClient';
export const ContactApi = {
    getRecument(type) {
        const url = `recruitments/search`;
        return axiosClient.get(url);
    },
    getDetailRecument(id) {
        const url = `recruitments//${id}`;
        return axiosClient.get(url);
    },
    applyRecument(data) {
        const url = `recruitments//apply`;
        return axiosClient.post(url, data);
    },
};
