import axiosClient from './AxiosClient';
export const aboutUsApi = {
    //25
    getCeoMessages() {
        const url = 'about-us/ceo-message';
        return axiosClient.get(url);
    },
    //26
    getCompanyInfo() {
        const url = 'about-us/company-info';
        return axiosClient.get(url);
    },
    //27
    getBussines() {
        const url = 'products/search?new=true';
        return axiosClient.get(url);
    },
    //29
    getHistory() {
        const url = 'about-us/history';
        return axiosClient.get(url);
    },
    //32
    getOurActivity() {
        const url = 'activities';
        return axiosClient.get(url);
    },
    //33
    getOurActivityDetail(id) {
        const url = `activities/${id}`;
        return axiosClient.get(url);
    },
    //34
    getLocation() {
        const url = 'offices';
        return axiosClient.get(url);
    },
    //35
    getPartner() {
        const url = 'partner';
        return axiosClient.get(url);
    },
    //36
    getActivitiesDetail(id) {
        const url = `activities/${id}`;
        return axiosClient.get(url);
    },
    //37
    getPromotion() {
        const url = 'promotion-videos';
        return axiosClient.get(url);
    },

    //38
    getNewProducts() {
        const url = 'newsletter?type=NEWS';
        return axiosClient.get(url);
    },
    //39
    getNewProductDetail(id) {
        const url = `newsletter/${id}`;
        return axiosClient.get(url);
    },
    //40
    getAllNewLetterByType(type) {
        const url = `newsletter?type=${type}`;
        return axiosClient.get(url);
    },
    //42
    getNewOthers(id) {
        const url = `newsletter/others/${id}`;
        return axiosClient.get(url);
    },
    //43//44 //45
    getReport(type) {
        const url = `report?type=${type}`;
        return axiosClient.get(url);
    },
    //46,47,48
    getSaleChanelByType(type) {
        const url = `sale/search?type=${type}`;
        return axiosClient.get(url);
    },
};
