import { createAsyncThunk } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ProductApi } from '../../api/ProductApi';

export const getField = createAsyncThunk('product/getField', async () => {
    const listField = await ProductApi.getFieldProduct();
    return listField;
});

export const getProductById = createAsyncThunk('product/getprobyId', async (cateId) => {
    const listProductbyId = await ProductApi.getProductById(cateId);
    return listProductbyId;
});
export const getDetailProduct = createAsyncThunk('product/detail', async (id) => {
    const detailProduct = await ProductApi.getDetail(id);
    return detailProduct;
});
export const getRelatedProduct = createAsyncThunk('product/related', async (id) => {
    const relatedProduct = await ProductApi.getRelated(id);
    return relatedProduct;
});
export const getProductPageHome = createAsyncThunk('home/getProductPageHome', async () => {
    const res = await ProductApi.getProductPageHome();
    return res;
});
export const getListProduct = createAsyncThunk('product/listProduct', async () => {
    const res = await ProductApi.getListProduct();
    return res;
});

export const getListCategoryProducts = createAsyncThunk('product/listCategoryProduct', async (id) => {
    const res = await ProductApi.getListCategoryProduct(id);
    return res;
});
const productSlice = createSlice({
    name: 'product',
    initialState: {
        listProductbyId: [],
        loadingProduct: false,
        detail: {},
        listField: [],
        listProductPageHome: [],
        relatedProduct: [],
        listProduct: [],
        listCategoryProduct: [],
    },
    reducers: {},
    extraReducers: {
        [getProductById.pending]: (state) => {
            state.loadingProduct = true;
        },
        [getProductById.rejected]: (state) => {
            state.loadingProduct = false;
        },
        [getProductById.fulfilled]: (state, action) => {
            state.loadingProduct = false;
            state.listProductbyId = action.payload.data;
        },
        [getDetailProduct.pending]: (state) => {
            state.loadingProduct = true;
        },
        [getDetailProduct.rejected]: (state) => {
            state.loadingProduct = false;
        },
        [getDetailProduct.fulfilled]: (state, action) => {
            state.loadingProduct = false;
            state.detail = action.payload.data;
        },
        [getField.pending]: (state) => {
            state.loadingProduct = true;
        },
        [getField.rejected]: (state) => {
            state.loadingProduct = false;
        },
        [getField.fulfilled]: (state, action) => {
            state.loadingProduct = false;
            state.listField = action.payload.data;
        },
        [getRelatedProduct.pending]: (state) => {
            state.loadingProduct = true;
        },
        [getRelatedProduct.rejected]: (state) => {
            state.loadingProduct = false;
        },
        [getRelatedProduct.fulfilled]: (state, action) => {
            state.loadingProduct = false;
            state.relatedProduct = action.payload.data;
        },
        [getProductPageHome.pending]: (state) => {
            state.loadingHome = true;
        },
        [getProductPageHome.rejected]: (state) => {
            state.loadingHome = false;
        },
        [getProductPageHome.fulfilled]: (state, action) => {
            state.loadingHome = false;
            state.listProductPageHome = action.payload.data;
        },
        [getListProduct.pending]: (state) => {
            state.loadingProduct = true;
        },
        [getListProduct.rejected]: (state) => {
            state.loadingProduct = false;
        },
        [getListProduct.fulfilled]: (state, action) => {
            state.loadingProduct = false;
            state.listProduct = action.payload.data;
        },
        [getListCategoryProducts.pending]: (state) => {
            state.loadingApplication = true;
        },
        [getListCategoryProducts.rejected]: (state) => {
            state.loadingApplication = false;
        },
        [getListCategoryProducts.fulfilled]: (state, action) => {
            state.loadingApplication = false;
            state.listCategoryProduct = action.payload.data;
        },
    },
});

const { reducer: productReducer } = productSlice;
export default productReducer;
