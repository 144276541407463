const path = {
    HOME: '/',

    DETAIL_NEWS: '/news/:id',

    ADMINHOME: '/admin',

    LOGIN: '/login',
    REGISTER: '/register',
    FORGET: '/forget-password',
    CHANGE_PASSWORD: '/change-password',

    //product
    PRODUCT: '/product',
    LISTPRODUCT: '/product/list/:id',
    AUTOMATIVEPRODUCT: '/product/product-detail/:id',
    LIST_VEHICLE_FILE: 'product/:id',
    TIRE_SENSOR: 'product-detail/:id',

    //AboutUs
    ABOUT: '/about',
    CEO: '/about/ceo-messages',
    COMPANY_INFO: '/about/company-info',
    BUSSINESS: '/about/bussiness',
    OURMISSION: '/about/our-mission',
    QUALITY: '/about/quality',
    ACTIVITY: '/about/activity',
    ACTIVITYDETAIL: '/activities/:id',
    HISTORY: '/about/history',
    LOCATION: '/about/location',
    CONTACTUS: '/about/contact',
    PARTNER: '/about/partner',
    PROMOTION: '/about/promotion',
    PROMOTION: '/about/promotion',
    NEWS: '/about/news',
    NEWPRODUCT: '/about/new-product',
    NEWPRODUCTDETAIL: '/about/news/:id',
    CORPORATION: '/about/corporation',
    ANNOUNCED: '/about/announced',
    DOCUMENT: '/about/document',
    REPORT: '/about/report',
    DOMESTIC: '/about/domestic',
    INTERNATIONAL: '/about/international',
    ONLINE: '/about/online',

    //Contact
    DETAIL: '/contact/recruiment/:id',
    CONTACT: '/contact',
    RECRUIMENT: '/contact/recruiment',
    RECUITMENT_DETAIL: '/recuitment/:id',
    COOPERATE: '/cooperate',
    ECOREMERCE: '/ecoremerce',

    //Support
    SUPPORT: '/support',
    SUPPORT_DETAIL: '/support/detail-support/:id',

    //application

    APPLICATION: '/application',
    LISTAPPLICATION: '/application/list/:id',
    LEGALAPPLICATION: '/application/LegalTech',
    EDUAPPLICATION: '/application/EduTech',
    AGRIAPPLICATION: '/application/AgriTech',
    HEALTHAPPLICATION: '/application/HeathTech',
    ERPAPPLICATION: '/application/ERP',
    TELECOMMUNICATIONAPPLICATION: '/application/telecommunication',
    DETAILAPPLICATIONFEATURE: 'application/feature/:id',
    DETAILAPPLICATIONSPECIAL: 'application/special/:id',

    APPLICATION_ECOREMERCE: '/application/ecoremerce',
    APPLICATION_LEGAL_TECHNOLOGY: '/application/:id',
    DETAIL_LEGAL_TECHNOLOGY: 'application/application-detail/:id',

    //service

    SERVICE: '/service',
    SERVICE_DETAIL: '/service/detail-service/:id',

    PRIVACY_POLICY: 'privacy-policy',
    TERM_OF_USE: 'term-of-use',
    LEGAL_POLICY: 'legal-policy',
    SITE_MAP: 'site-map',
};

export default path;
